import { FC } from 'react'
import { useSelector } from 'react-redux'

import { roleFormSelector } from '@store/roleForms/selectors'

import { Businessman } from '@pages/RoleForms'

export const BusinessmanFulfilled: FC = () => {
    const data = useSelector(roleFormSelector.selectBusinessmanFormValue)

    if (!data?.id) {
        return null
    }

    return <Businessman />
}
