import { FC } from 'react'
import { useSelector } from 'react-redux'

import { roleFormSelector } from '@store/roleForms/selectors'

import { Individual } from '@pages/RoleForms'

export const IndividualFulfilled: FC = () => {
    const data = useSelector(roleFormSelector.selectIndividualFormValue)

    if (!data?.id) {
        return null
    }

    return <Individual />
}
