import {
    Box,
    Card,
    CardContent,
    CardField,
    CardHeader,
    CopyButton,
    Divider,
    PageHeader,
    StepperC,
    VIEW_DATE_FORMAT,
} from '@dltru/dfa-ui'
import dayjs from 'dayjs'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { authSelector } from '@store/auth/selectors'
import { profileSelector } from '@store/profiles/details/selectors'
import { getCurrentStatementQualify } from '@store/qualifyStatement'
import { qualifyStatementSelector } from '@store/qualifyStatement/selectors'

import { InformationAboutUser } from '@pages/ClientCard/MyProfile/components/QualificationTab/components/InformationAboutUser'
import { Status } from '@pages/ClientCard/MyProfile/components/QualificationTab/components/Status'

import { NoteAlert, RejectAlert } from './components/Alerts'
import { Footer } from './components/Footer'
import { QualificationStatementDocs } from './components/QualificationStatementDocs/QualificationStatementDocs'
import { IQualForm } from './type'

export const QualificationStatement: FC = () => {
    const reduxDispatch = useDispatch()
    const { id } = useParams()
    const profileAgent = useSelector(profileSelector.selectProfileAgent)
    const profile = useSelector(profileSelector.selectProfile)
    const profileFullName = useSelector(profileSelector.userFullName)
    const profileagentFullName = useSelector(profileSelector.agentFullName)
    const type = useSelector(profileSelector.selectProfileTypes)
    const qualifyStatement = useSelector(qualifyStatementSelector.selectCurrentQualifyStatement)
    const isLoadedQualify = useSelector(qualifyStatementSelector.selectIsLoaded)
    const userID = useSelector(authSelector.selectUserUid)
    const [isStatementComplete, setStatementComplete] = useState(false)
    const [formdata, setFormdata] = useState<IQualForm>()

    useEffect(() => {
        reduxDispatch(getCurrentStatementQualify(`?uuid=${id}`))
    }, [])

    useEffect(() => {
        if (isLoadedQualify) {
            reduxDispatch(getCurrentStatementQualify(`?uuid=${id}`))
        }
    }, [isLoadedQualify])

    useEffect(() => {
        if (qualifyStatement) {
            const formValue = {
                ...qualifyStatement,
                operator_uuid: userID || '',
                operator_role: 'operator',
                application_uuid: id || '',
                attachments: qualifyStatement?.documents?.map((doc) => ({ uid: doc.link_uuid })),
            }
            setFormdata(formValue)
        }
    }, [qualifyStatement])

    const isShowFooter =
        qualifyStatement?.status === 'draft' || qualifyStatement?.status === 'under_revision'

    return (
        <>
            <Box padding={16}>
                <PageHeader
                    title="Заявление о признании квалифицированным инвестором"
                    subTitle={
                        <>
                            ID&ensp; {qualifyStatement?.uuid || ''}
                            <CopyButton text={qualifyStatement?.uuid || ''} />
                        </>
                    }
                    onBack={() => window.history.back()}
                    tags={qualifyStatement ? <Status status={qualifyStatement?.status} /> : <></>}
                />
            </Box>

            <div style={{ marginBottom: 8 }}>
                {qualifyStatement?.status === 'under_revision' && (
                    <NoteAlert data={qualifyStatement} />
                )}
                {qualifyStatement?.status === 'declined' && <RejectAlert data={qualifyStatement} />}
            </div>

            <Card className="boxContent">
                <CardHeader title="Основная информация" />
                {qualifyStatement?.created_at && (
                    <CardField
                        title="Дата подачи заявки"
                        value={dayjs.unix(qualifyStatement?.created_at).format(VIEW_DATE_FORMAT)}
                        padding={16}
                    />
                )}
                <CardField
                    title="Заявитель"
                    value={
                        <>
                            <span>{profileFullName}</span>&ensp; ID&ensp; {profile?.user_uuid}
                            {profile?.user_uuid && <CopyButton text={profile.user_uuid} />}
                        </>
                    }
                    padding={16}
                />
                <Divider />
                <CardContent>
                    <InformationAboutUser
                        profileAgent={profileAgent}
                        profile={profile}
                        profileFullName={profileFullName}
                        profileagentFullName={profileagentFullName}
                        profileType={type ? type[0] : undefined}
                    />
                </CardContent>
            </Card>

            {formdata && (
                <StepperC
                    initialValues={formdata}
                    initialStep={1}
                    onStepperFinish={(value) => {
                        const formValue = {
                            ...(value as IQualForm),
                            user_uuid: userID || '',
                            user_name: profileFullName || '',
                            application_uuid: id || '',
                        }
                        setFormdata(formValue)
                        setStatementComplete(true)
                    }}
                >
                    <QualificationStatementDocs stepIndex={0} isModification={isShowFooter} />
                </StepperC>
            )}

            {isShowFooter && <Footer isStatementComplete={isStatementComplete} data={formdata} />}
        </>
    )
}
