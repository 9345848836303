import { Button, CheckIcon } from '@dltru/dfa-ui'
import { FC, memo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { putStatementRequestQualify } from '@store/qualifyStatement'

import { qualFormToPutRequest } from '@pages/CreateQualificationStatement/utils'

import { IActionProps } from '../../../type'
import { ApplyActionModal } from '../Modals/ApplyActionModal'

export const ApplyAction: FC<IActionProps> = memo(({ data }) => {
    const reduxDispatch = useDispatch()
    const [isModalVisible, setIsModalVisible] = useState(false)

    const onOkHandler = () => {
        if (data) {
            const formValue = qualFormToPutRequest(data)
            if (formValue) {
                reduxDispatch(putStatementRequestQualify(formValue))
            }
        }
        setIsModalVisible(false)
    }

    return (
        <>
            <Button
                borderRadius={16}
                onClick={() => setIsModalVisible(true)}
                type="primary"
                icon={<CheckIcon />}
            >
                Подать заявление
            </Button>
            <ApplyActionModal
                isModalVisible={isModalVisible}
                onOk={onOkHandler}
                onCancel={() => {
                    setIsModalVisible(false)
                }}
            />
        </>
    )
})
