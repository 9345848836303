import {
    AuthForm,
    Box,
    Button,
    CheckEmailModal,
    Form,
    MAX_LOGIN_LENGTH,
    WrappedInput,
    authValidators,
    contactValidators,
} from '@dltru/dfa-ui'
import { FC, useState } from 'react'

import api from '@services/api'

import { validatorConstructor } from '../../../resources/inputValidators'

type RegistrationFormDataType = {
    login: string
    email: string
}

export const RegisterForm: FC = () => {
    const [error, setError] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [form] = Form.useForm()

    const sendRegistrationData = async ({ login, email }: RegistrationFormDataType) => {
        const { error } = await api.lib.registrationService(login, email)

        if (!error) {
            setShowModal(true)
            return
        }
        const errorMessage = error.response?.data?.error?.message

        setError(errorMessage ?? '')
        await form.validateFields(['login', 'email'])
    }
    const onChangeInput = () => {
        setError('')
        form.validateFields(['login', 'email'])
    }
    return (
        <>
            <AuthForm title="Регистрация" onFinish={sendRegistrationData} form={form}>
                <WrappedInput
                    label="Логин"
                    name="login"
                    size="large"
                    placeholder="Придумайте логин. Например, Vladimir"
                    onChange={onChangeInput}
                    required
                    maxLength={MAX_LOGIN_LENGTH}
                    rules={[
                        { validator: authValidators.login },
                        validatorConstructor(
                            error === 'login exists',
                            'Такой пользователь уже существует в системе',
                        ),
                    ]}
                />

                <WrappedInput
                    name="email"
                    label="Почта"
                    size="large"
                    placeholder="Например, example@example.ru"
                    onChange={onChangeInput}
                    required
                    rules={[
                        { validator: contactValidators.email },
                        validatorConstructor(
                            error === 'email exists',
                            'Такой пользователь уже существует в системе',
                        ),
                        validatorConstructor(
                            error === '404 page not found',
                            'Некорректный ответ с сервера',
                        ),
                    ]}
                />

                <Box direction="row" justify="right" padding={[24, 0, 0, 0]}>
                    <Button borderRadius={16} type="primary" htmlType="submit" size="large">
                        Зарегистрироваться
                    </Button>
                </Box>
            </AuthForm>

            <CheckEmailModal
                visible={showModal}
                onCancel={() => setShowModal(false)}
                onOk={() => setShowModal(false)}
            />
        </>
    )
}
