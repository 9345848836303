import { frontUrl } from '@constants/frontUrl'
import {
    IAgentProfile,
    IBusinessmanProfile,
    IIndividualProfile,
    ILegalEntityProfile,
    ProfileType,
} from '@dltru/dfa-models'
import React, { FC, useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'

import { profileSelector } from '@store/profiles/details/selectors'
import { roleFormsSlice } from '@store/roleForms'
import { roleFormSelector } from '@store/roleForms/selectors'

import { BusinessmanFulfilled } from '@pages/Questionnaire/BusinessmanFullfilled'
import { ClientRepresentativeFulfilled } from '@pages/Questionnaire/ClientRepresentativeFulfilled'
import { IndividualFulfilled } from '@pages/Questionnaire/IndividualFullfilled'
import { LegalEntityFulfilled } from '@pages/Questionnaire/LegalEntityFulfilled'

export const QuestionnaireRoutes: FC = () => {
    const reduxDispatch = useDispatch()
    const profile = useSelector(profileSelector.selectProfile)
    const profileAgent = useSelector(profileSelector.selectProfileAgent)
    const types = useSelector(profileSelector.selectProfileTypes)
    const beneficiaryUuids = useSelector(profileSelector.selectBeneficiariesUuids) as string[]
    const beneficiaries = useSelector(roleFormSelector.selectBeneficialOwnerAnketa)

    useLayoutEffect(() => {
        if (profileAgent) {
            reduxDispatch(
                roleFormsSlice.actions.updateAgentAnketa({
                    id: 'view',
                    ...profileAgent,
                } as IAgentProfile),
            )
        }
        if (profile && types?.includes(ProfileType.LEGL)) {
            reduxDispatch(
                roleFormsSlice.actions.updateLegalEntityAnketa({
                    id: 'view',
                    ...profile,
                } as ILegalEntityProfile),
            )
        } else if (profile && types?.includes(ProfileType.BUSN)) {
            reduxDispatch(
                roleFormsSlice.actions.updateBusinessmanAnketa({
                    id: 'view',
                    ...profile,
                } as IBusinessmanProfile),
            )
        } else if (profile) {
            reduxDispatch(
                roleFormsSlice.actions.updateIndividualAnketa({
                    id: 'view',
                    ...profile,
                } as IIndividualProfile),
            )
        }
    }, [profile, profileAgent])

    useEffect(() => {
        if (beneficiaryUuids) {
            reduxDispatch(roleFormsSlice.actions.getBeneficiaries(beneficiaryUuids))
        }
    }, [beneficiaryUuids])

    if ((!profile && !profileAgent) || (beneficiaryUuids && !beneficiaries)) {
        return null
    }
    return (
        <Routes>
            <Route
                path={frontUrl.questionnaireRepresentative}
                element={<ClientRepresentativeFulfilled />}
            />
            <Route path={frontUrl.questionnaireIndividual} element={<IndividualFulfilled />} />
            <Route path={frontUrl.questionnaireLegalEntity} element={<LegalEntityFulfilled />} />
            <Route path={frontUrl.questionnaireBusinessman} element={<BusinessmanFulfilled />} />
        </Routes>
    )
}
