import { FC } from 'react'
import { useSelector } from 'react-redux'

import { roleFormSelector } from '@store/roleForms/selectors'

import { LegalEntity } from '@pages/RoleForms'

export const LegalEntityFulfilled: FC = () => {
    const data = useSelector(roleFormSelector.selectLegalEntityAnketa)

    if (!data?.id) {
        return null
    }

    return <LegalEntity />
}
