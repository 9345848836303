import { CONTENT_FOOTER_ID, ContentFooterBox, Portal } from '@dltru/dfa-ui'
import { FC } from 'react'

import { IQualForm } from '../../type'
import { ApplyAction } from './Actions/ApplyAction'

interface IFooterProps {
    isStatementComplete: boolean
    data?: Partial<IQualForm>
}

export const Footer: FC<IFooterProps> = ({ data }) => {
    const contentFooter = document.getElementById(CONTENT_FOOTER_ID)

    return (
        <>
            {contentFooter && typeof contentFooter !== null && (
                <Portal container={contentFooter}>
                    <ContentFooterBox>
                        {/* не входит в 095 <DeleteAction data={data} />*/}

                        <ApplyAction data={data} />
                    </ContentFooterBox>
                </Portal>
            )}
        </>
    )
}
