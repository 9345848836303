import { Box, Button, Divider, Modal, Price } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useSelector } from 'react-redux'

import { moneySelector } from '@store/money/selectors'

import { WalletWidget } from '@components/WalletWidget'

import style from './ApplyActionModal.m.less'

interface IConfirmModal {
    isModalVisible: boolean
    onOk: () => void
    onCancel: () => void
    dfaId?: string
}

export const ApplyActionModal: FC<IConfirmModal> = ({ isModalVisible, onOk, onCancel }) => {
    const account = useSelector(moneySelector.selectAccount)

    return (
        <>
            <Modal
                visible={isModalVisible}
                width={522}
                title="Отправить заявление на рассмотрение"
                onCancel={onCancel}
            >
                <div className={style.confirmRepaymentModal}>
                    <Box direction="column" margin={[0, 0, 24, 0]}>
                        <p className="modal-content-text">
                            Вы действительно хотите направить заявление о признании
                            квалифицированным инвестором Оператору ИС?
                        </p>
                        <Divider />
                        <p className="modal-content-text">
                            Сумма комиссии за рассмотрение заявления о признании квалифицированным
                            инвестором
                        </p>
                        <p>
                            <Price price={0} />
                        </p>
                        <WalletWidget
                            balance={account?.balance?.available}
                            account={String(account?.number)}
                            error={false}
                        />
                    </Box>
                    <Box
                        className="ant-modal-footer ant-modal-footer-no-border"
                        padding={[0, 8, 16, 8]}
                        direction="row"
                        justify="right"
                    >
                        <Button borderRadius={12} onClick={onCancel}>
                            Отмена
                        </Button>

                        <Button borderRadius={12} type="primary" onClick={() => onOk()}>
                            Да, направить заявление
                        </Button>
                    </Box>
                </div>
            </Modal>
        </>
    )
}
