import { Box } from '@dltru/dfa-ui'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { logout } from '@store/auth'

import { UnauthorizedHeader } from '@components/Header'
import { ContentBox } from '@components/Layout/ContentBox'
import { AuthSideBox } from '@components/SideBox'

import { RegisterForm } from './components/RegisterForm'

export const Register = () => {
    const reduxDispatch = useDispatch()

    useEffect(() => {
        reduxDispatch(logout())
    }, [])

    return (
        <>
            <UnauthorizedHeader />
            <ContentBox type="unauthorized">
                <Box direction="row" align="center" justify="center" height="100%">
                    <Box direction="row">
                        <AuthSideBox />
                        <RegisterForm />
                    </Box>
                </Box>
            </ContentBox>
        </>
    )
}
